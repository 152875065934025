import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/glow.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Gallery from './components/Gallery';
import BWGallery from './components/BWGallery';
import About from './components/About';
import Contact from './components/Contact';

function App() {
  const currentDomain = window.location.hostname;
  
  // Allow main domains and Cloudflare Access callback
  if (currentDomain !== 'chadwicknft.com' && 
      currentDomain !== 'web3.chadwicknft.com' && 
      currentDomain !== 'chadwicknft.cloudflareaccess.com') {
    return (
      <div className="min-h-screen bg-rich-black text-white flex items-center justify-center">
        <div className="text-center p-8">
          <h1 className="text-4xl font-bold mb-4">Access Denied</h1>
          <p className="text-xl mb-4">This site can only be accessed via authorized domains.</p>
          <p className="text-lg">Please visit <a href="https://chadwicknft.com" className="text-blue-400 hover:text-blue-300">chadwicknft.com</a></p>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen bg-rich-black text-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/bw-gallery" element={<BWGallery />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
